import React from 'react';

const Signup = (props) => (
  <svg viewBox="0 0 25 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.644775 3C0.644775 1.34315 1.98792 0 3.64478 0H21.6449C23.3017 0 24.6449 1.34315 24.6449 3V3.83117C24.6449 3.87234 24.6424 3.91292 24.6375 3.95279C24.5413 5.49835 23.4924 6.83376 21.9936 7.28341L13.7942 9.74324C13.0444 9.96817 12.2451 9.96817 11.4954 9.74324L3.29595 7.28341C3.06788 7.21499 2.85023 7.12607 2.64478 7.01901V17C2.64478 17.5523 3.09249 18 3.64477 18H5.35075C5.90304 18 6.35075 18.4477 6.35075 19C6.35075 19.5523 5.90304 20 5.35075 20H3.64477C1.98792 20 0.644775 18.6569 0.644775 17V3ZM2.64478 3.72015C2.64478 4.47979 3.14305 5.14948 3.87065 5.36776L12.0701 7.82759C12.445 7.94005 12.8446 7.94005 13.2195 7.82759L21.4189 5.36776C22.1465 5.14948 22.6448 4.47979 22.6448 3.72015C22.6448 2.77014 21.8746 2 20.9246 2H4.36493C3.41491 2 2.64478 2.77014 2.64478 3.72015ZM24.6448 15.5C24.6448 15.7745 24.5319 16.0369 24.3327 16.2258L20.6404 19.7258C20.2396 20.1057 19.6067 20.0888 19.2267 19.688C18.8468 19.2871 18.8637 18.6542 19.2645 18.2742L21.1362 16.5H11.6448C11.0925 16.5 10.6448 16.0523 10.6448 15.5C10.6448 14.9477 11.0925 14.5 11.6448 14.5H21.1362L19.2645 12.7258C18.8637 12.3458 18.8468 11.7129 19.2267 11.312C19.6067 10.9112 20.2396 10.8943 20.6404 11.2742L24.3327 14.7742C24.5319 14.9631 24.6448 15.2255 24.6448 15.5Z"
      fill="#54C8E8"
    />
  </svg>
);

export default Signup;
