import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const Container = ({ children, fullWidth, noGutters, className, ...other }) => (
  <div
    className={classNames(
      'container',
      fullWidth && 'container--full-width',
      noGutters && 'container--no-gutters',
      className
    )}
    {...other}
  >
    {children}
  </div>
);

Container.defaultProps = {
  className: null,
  fullWidth: false,
};

Container.propTypes = {
  /** A custom class name. */
  className: PropTypes.string,
  /** Prevent the narrow container on mobile and tablet */
  fullWidth: PropTypes.bool,
  /** Removes the padding on the container */
  noGutters: PropTypes.bool,
};

export default Container;
