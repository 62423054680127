import React from 'react';

const Email = (props) => {
  return (
    <svg
      role="img"
      aria-label="Email icon"
      width="26"
      height="21"
      viewBox="0 0 26 21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.90002 0.309814H20.9C23.6614 0.309814 25.9 2.54839 25.9 5.30981V15.3098C25.9 18.0712 23.6614 20.3098 20.9 20.3098H5.90002C3.1386 20.3098 0.900024 18.0712 0.900024 15.3098V5.30981C0.900024 2.54839 3.1386 0.309814 5.90002 0.309814ZM5.90002 2.80981C4.51931 2.80981 3.40002 3.9291 3.40002 5.30981V15.3098C3.40002 16.6905 4.51931 17.8098 5.90002 17.8098H20.9C22.2807 17.8098 23.4 16.6905 23.4 15.3098V5.30981C23.4 3.9291 22.2807 2.80981 20.9 2.80981H5.90002Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.20098 0.309814H21.5991C23.9744 0.309814 25.9 2.23542 25.9 4.61077C25.9 6.49194 24.6775 8.15484 22.8819 8.71594L14.8914 11.213C13.9203 11.5165 12.8797 11.5165 11.9086 11.213L3.91811 8.71594C2.12257 8.15484 0.900024 6.49194 0.900024 4.61077C0.900024 2.23542 2.82563 0.309814 5.20098 0.309814ZM5.20098 2.80981C4.20634 2.80981 3.40002 3.61613 3.40002 4.61077C3.40002 5.39848 3.91195 6.09479 4.6638 6.32974L12.6543 8.82679C13.1399 8.97852 13.6602 8.97852 14.1457 8.82679L22.1362 6.32974C22.8881 6.09479 23.4 5.39848 23.4 4.61077C23.4 3.61613 22.5937 2.80981 21.5991 2.80981H5.20098Z"
      />
    </svg>
  );
};

export default Email;
