import React from 'react';

const Facebook = (props) => (
  <svg
    role="img"
    aria-label="Facebook icon"
    width="14"
    height="25"
    viewBox="0 0 14 25"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M13.5308 11.3867H9.65387V24.3098H4.1154V11.3867H0.423096V7.69443H4.1154V5.47905C4.1154 3.44828 4.85387 0.309814 8.91541 0.309814H13.3462V4.55597H10.7616C10.3923 4.55597 9.65387 4.74058 9.65387 5.66366V7.50981H13.9L13.5308 11.3867Z" />
  </svg>
);

export default Facebook;
