import React from 'react';

const Share = (props) => (
  <svg viewBox="0 0 25 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4079 0.0908266C13.7635 -0.0720282 14.1814 -0.0126254 14.4776 0.24287L24.298 8.71571C24.5105 8.89903 24.6362 9.16318 24.6444 9.44368C24.6525 9.72417 24.5425 9.99521 24.3411 10.1906L14.5206 19.7178C14.2323 19.9975 13.8046 20.0775 13.4347 19.921C13.0647 19.7644 12.8243 19.4017 12.8243 19V14.0701C6.59747 14.3461 3.3869 17.1819 2.58219 19.3482C2.41364 19.802 1.94429 20.0699 1.46788 19.9842C0.991463 19.8986 0.644775 19.4841 0.644775 19C0.644775 13.2325 2.57712 9.86338 5.36135 8.00158C7.71125 6.43021 10.5183 6.04171 12.8243 5.96713V1.00001C12.8243 0.608899 13.0523 0.253681 13.4079 0.0908266ZM14.8243 3.18354V6.95183C14.8243 7.50411 14.3766 7.95183 13.8243 7.95183C11.4324 7.95183 8.64918 8.20898 6.47309 9.66412C4.90106 10.7153 3.53175 12.4737 2.94677 15.5193C5.18499 13.532 8.84625 12.0482 13.8243 12.0482C14.3766 12.0482 14.8243 12.4959 14.8243 13.0482V16.6366L22.1641 9.51609L14.8243 3.18354Z"
      fill="#54C8E8"
    />
  </svg>
);

export default Share;
