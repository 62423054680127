import classNames from 'classnames';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import './styles.scss';

const Button = forwardRef(
  (
    {
      to,
      className,
      inverse,
      children,
      disabled,
      link,
      forceExternal,
      ...other
    },
    ref
  ) => {
    const file = /\.[0-9a-z]+$/i.test(to);
    const internal = /^\/(?!\/)/.test(to);

    const getClassnames = () =>
      classNames(
        'button',
        inverse && 'button--inverse',
        disabled && 'button--disabled',
        link && 'button--link',
        className
      );

    if (to) {
      if (forceExternal || !internal || file) {
        return (
          <a href={to} className={getClassnames()} {...other}>
            {children}
          </a>
        );
      }

      return (
        <Link to={to} className={getClassnames()} {...other}>
          {children}
        </Link>
      );
    }

    return (
      <button
        className={getClassnames()}
        disabled={disabled}
        ref={ref}
        {...other}
      >
        {children}
      </button>
    );
  }
);

Button.defaultProps = {
  to: null,
  className: null,
  inverse: false,
  disabled: false,
  link: false,
  forceExternal: false,
};

Button.propTypes = {
  /** A path to an internal page or a URL to an external page. */
  to: PropTypes.string,
  /** A custom class name */
  className: PropTypes.string,
  /** Inverts the buttons colors. */
  inverse: PropTypes.bool,
  /** Disables events and mutes the colors. */
  disabled: PropTypes.bool,
  /** Renders a button element that is styled like a link */
  link: PropTypes.bool,
  /** If true, URL will open in a new tab */
  forceExternal: PropTypes.bool,
};

export default Button;
