import React from 'react';

const Twitter = (props) => (
  <svg
    role="img"
    aria-label="Twitter icon"
    width="25"
    height="21"
    viewBox="0 0 25 21"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M22.1154 5.30982V5.88674C22.1154 12.6175 17.1154 20.3098 7.69231 20.3098C4.80769 20.3098 2.11538 19.5406 0 18.0021C2.69231 18.3867 5.38462 17.6175 7.5 15.8867C5.19231 15.8867 3.46154 14.3483 2.69231 12.4252C3.46154 12.6175 4.23077 12.6175 5 12.4252C2.69231 12.0406 0.961538 9.9252 0.961538 7.4252C1.73077 7.80982 2.5 8.00212 3.26923 8.00212C1.92308 7.04058 0.961538 5.50212 0.961538 3.77135C0.961538 2.80981 1.15385 2.04058 1.73077 1.27135C4.23077 4.34828 7.88462 6.27135 12.1154 6.65597C11.9231 6.07905 11.9231 5.69443 11.9231 5.30982C11.9231 2.4252 14.2308 0.309814 16.9231 0.309814C18.4615 0.309814 19.6154 0.886738 20.5769 1.84828C21.7308 1.65597 24.4231 1.27135 24.4231 1.27135C23.6538 2.4252 23.0769 4.54059 22.1154 5.30982Z" />
  </svg>
);

export default Twitter;
